import { serverApi } from "@/helpers/api";
import { IncomingMessage } from "http";
import { MatterType, PlatformType, MainPageDataType } from "./types";
import { getFromMemcached } from "@/helpers/memcached";

type NotFoundType = { notFound: true };

type MatterReturnType =
  | { data: MatterType; cached: boolean }
  | { redirectPath: string }
  | NotFoundType;

type MatterExternalReturnType =
  | { data: { matter: MatterType; platform: PlatformType }; cached: boolean }
  | { redirectPath: string }
  | NotFoundType;

type MatterPropsType = {
  resolvedUrl: string;
  req: IncomingMessage;
};

export async function getMatter({
  resolvedUrl,
  req,
}: MatterPropsType): Promise<MatterReturnType> {
  let matter: MatterType;
  let cached = false;
  const resolvedUrlWithoutGetParams = resolvedUrl.replace(/\/?\?.*$/, "");
  const matterStr = await getFromMemcached(
    `matter:${resolvedUrlWithoutGetParams}:platform:${req.headers.host}`
  );
  if (matterStr) {
    matter = JSON.parse(matterStr) as MatterType;
    cached = true;
  } else {
    const response = await serverApi<MatterType | { redirect_path: string }>(
      `matters${resolvedUrlWithoutGetParams}`,
      {
        req,
      }
    );
    if ("notFound" in response) return response;
    if ("redirect_path" in response.data)
      return { redirectPath: response.data.redirect_path };
    matter = response.data;
  }
  return { data: matter, cached };
}

// prepends origin to all relative urls
function replacePaths(object: MatterType, origin: string): MatterType {
  if (typeof object !== "object" || object === null) {
    return object;
  }

  for (const key in object) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
    if (typeof object[key] === "string" && object[key].startsWith("/")) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      object[key] = `${origin}${object[key]}`;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (typeof object[key] === "object") {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        replacePaths(object[key], origin);
      }
    }
  }

  return object as MatterType;
}

export async function getMatterExternal({
  resolvedUrl,
  req,
}: MatterPropsType): Promise<MatterExternalReturnType> {
  const urlObj = new URL(resolvedUrl);
  const platformUrl = `${urlObj.origin}/api/site/platform`;
  const matterReq = serverApi<MatterType | { redirect_path: string }>(
    resolvedUrl,
    { req }
  );
  const platformReq = serverApi<PlatformType>(platformUrl, { req });
  const [matterResult, platformResult] = await Promise.all([
    matterReq,
    platformReq,
  ]);
  let matter: MatterType;
  if ("notFound" in matterResult) return matterResult;
  if ("notFound" in platformResult) return platformResult;

  // silent request for redirected url
  if ("redirect_path" in matterResult.data) {
    const urlObj = new URL(matterResult.data.redirect_path);
    const redirectedUrl = `${urlObj.origin}/api/site/matters${urlObj.pathname}`;
    const matterRedirectedResult = await serverApi<MatterType>(redirectedUrl, {
      req,
    });
    if ("notFound" in matterRedirectedResult) return matterRedirectedResult;
    matter = replacePaths(matterRedirectedResult.data, urlObj.origin);
    // return { redirectPath: matterResult.data.redirect_path };
  } else {
    matter = matterResult.data;
  }

  matter = replacePaths(matter, urlObj.origin);
  const platform = platformResult.data;
  return { data: { matter, platform }, cached: false };
}

type PlatformReturnType = { platform: PlatformType } | NotFoundType;

export async function getPlatform(
  req: IncomingMessage
): Promise<PlatformReturnType> {
  let platform: PlatformType;
  const platformStr = await getFromMemcached(`platform:${req.headers.host}`);
  if (platformStr) {
    platform = JSON.parse(platformStr) as PlatformType;
  } else {
    try {
      const response = await serverApi<PlatformType>("platform", { req });
      if ("notFound" in response) return response;
      platform = response.data;
    } catch (e) {
      /* eslint no-console: "off" */
      console.error(e);
      return { notFound: true };
    }
  }
  return { platform };
}

type MainPageReturnType = { mainPageData: MainPageDataType } | NotFoundType;

export async function getMainPageData(
  req: IncomingMessage
): Promise<MainPageReturnType> {
  let mainPageData: MainPageDataType;
  const mainPageDataStr = await getFromMemcached(
    `main-page:${req.headers.host}`
  );
  if (mainPageDataStr) {
    mainPageData = JSON.parse(mainPageDataStr) as MainPageDataType;
  } else {
    try {
      const response = await serverApi<MainPageDataType>("main_page/config", {
        req,
      });
      if ("notFound" in response) return response;
      mainPageData = response.data;
    } catch (e) {
      /* eslint no-console: "off" */
      console.error(e);
      return { mainPageData: {} as MainPageDataType };
    }
  }
  return { mainPageData };
}

export const isNewizvDomain = ({ hostname }: PlatformType): boolean => {
  try {
    return hostname.includes("newizv");
  } catch (error) {
    return false;
  }
};
