import { FunctionComponent } from "react";
import { usePlatform } from "@/helpers/platformContext";
import styles from "../../styles/components/Topline/ToplineMenu.module.scss";

const Informers: FunctionComponent = () => {
  const { counters_informers_hidden, analytics, hostname } = usePlatform();

  return (
    <div className="flex">
      {!counters_informers_hidden && (
        <>
          <a
            href={`https://www.liveinternet.ru/click;${hostname}`}
            rel={"noreferrer"}
            className={"mb-[24px]"}
            target="_blank"
          >
            <img
              src={`https://counter.yadro.ru/logo;${hostname}?14.5`}
              title="LiveInternet: показано число просмотров за 24 часа, посетителей за 24 часа и за сегодня"
              alt=""
              style={{ border: 0 }}
              width="88"
              height="31"
            />
          </a>

          <a
            href={`https://metrika.yandex.ru/dashboard?id=${analytics.yandex_metrika_id}&from=informer`}
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={`https://informer.yandex.ru/informer/${analytics.yandex_metrika_id}/3_1_FFFFFFFF_EFEFEFFF_0_pageviews`}
              style={{ width: "88px", height: "31px" }}
              alt="Яндекс.Метрика"
              title="Яндекс.Метрика: данные за сегодня (просмотры, визиты и уникальные посетители)"
              className="ym-advanced-informer mx-2"
              data-cid={analytics.yandex_metrika_id}
              data-lang="ru"
            />
          </a>
        </>
      )}
      <div className={styles.limit18}></div>
    </div>
  );
};

export default Informers;
