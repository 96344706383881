/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

import React from "react";
import { createAppContext } from "./createContext";
import { SlugEnum } from "./slugEnums";
import { MatterType, TagType } from "./types";

type BannersContext = {
  hide_banners: boolean;
  bannersHeight: Record<`${SlugEnum}`, string | undefined>;
};

export const BannerContext = React.createContext(false);

export const [useBannerInitialContext, BannerInitialContext] =
  createAppContext<BannersContext>("BannerInitialContext");

export const getInitialBannerContext = (
  pageProps: any,
  bannersHeight: BannersContext["bannersHeight"]
): BannersContext => {
  let hide_banners = false;
  if (
    "initialMatters" in pageProps &&
    (pageProps.initialMatters as MatterType[] | TagType[]).length === 1 &&
    "hide_banners" in pageProps.initialMatters[0]
  ) {
    hide_banners = pageProps.initialMatters[0].hide_banners as boolean;
  }
  return { hide_banners, bannersHeight };
};

export const hideTransversalBanner = (slug: SlugEnum, needHide: boolean) => {
  if (
    needHide &&
    (slug === SlugEnum.all_catfish ||
      slug === SlugEnum.all_fullscreen ||
      slug === SlugEnum.all_header_before)
  )
    return true;
  return false;
};
