import styles from "./styles.module.scss";

const BottomFixedStack = ({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement => {
  // const el = useRef<HTMLDivElement>(null);

  // // manipulating dom directly to avoid multiple rerenders
  // useEffect(() => {
  //   let prevY = 0;
  //   let prevIsHidden = false;

  //   function scrollHandler() {
  //     if (!el.current) return;
  //     const scrollY = window.scrollY;
  //     const direction = prevY < scrollY ? "down" : "up";
  //     prevY = scrollY;

  //     const isHidden = direction === "down" && scrollY > 500;
  //     if (isHidden !== prevIsHidden) {
  //       isHidden
  //         ? (el.current.style.bottom = "0px")
  //         : (el.current.style.bottom = `-${el.current.clientHeight}px`);
  //       prevIsHidden = isHidden;
  //     }
  //   }

  //   window.addEventListener("scroll", scrollHandler, { passive: true });
  //   return () => window.removeEventListener("scroll", scrollHandler);
  // }, []);

  return <div className={styles.container}>{children}</div>;
};

export default BottomFixedStack;
