import React from "react";
import { FunctionComponent, useEffect } from "react";
import { HeaderBiddingsSettingsType } from "@/helpers/types";

declare global {
  interface Window {
    YaHeaderBiddingSettings?: HeaderBiddingsSettingsType;
  }
}

const HeaderBiddings: FunctionComponent<{
  settings?: HeaderBiddingsSettingsType;
}> = ({ settings }) => {
  useEffect(() => {
    window.YaHeaderBiddingSettings = settings;
  }, [settings]);

  return <></>;
};

export default React.memo(HeaderBiddings);
