import { useDevice } from "@/helpers/deviceContext";
import Link from "next/link";
import React, { useState, useEffect } from "react";

const CookieConsentPanel = () => {
  const [skip, setSkipNotify] = useState(true);
  const { isMobile } = useDevice();

  useEffect((): void => {
    // Можно через библиотеку js-cookie или cookie-js ...
    const getCookie = (name: string) => {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop()?.split(";").shift();
    };

    const consentAccepted =
      getCookie("cookie-accepted") ||
      location.hostname.includes("kubanpress") ||
      location.hostname.includes("newizv") ||
      location.hostname.includes("oilcapital") ||
      location.hostname.includes("zr.media");
    if (!consentAccepted) setSkipNotify(false);
  }, []);

  const setCookie = (name: string, value: string, days: number): void => {
    let expires = "";
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = `; expires=${date.toUTCString()}`;
    }
    document.cookie = `${name}=${value || ""}${expires}; path=/`;
  };

  const handleAccept = () => {
    setCookie("cookie-accepted", "true", 1000);
    setSkipNotify(true);
  };

  if (skip) return null;

  return (
    <div
      className={`bg-gray-800 text-white p-4 flex justify-between items-center rounded`}
      style={{ marginTop: "10px" }}
    >
      <div className={`mr-2 ${isMobile ? "text-sm" : "text-base"}`}>
        Пользуясь нашим сайтом, вы соглашаетесь с тем, что{" "}
        <Link href="/pages/privacy_policy">
          <a className="underline" target="_blank">
            мы используем cookies
          </a>
        </Link>
      </div>
      <button
        onClick={handleAccept}
        className="bg-gray-900 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
      >
        Хорошо
      </button>
    </div>
  );
};

export default CookieConsentPanel;
