import { PlatformType } from "@/helpers/types";
import React from "react";
import styles from "../styles/components/Contacts/styles.module.scss";
import Link from "next/link";

export type ContactsProps = {
  contacts: Pick<
    PlatformType["contacts"],
    | "legal_name"
    | "register_number"
    | "register_date"
    | "founder"
    | "chief_editor"
    | "founder_phone_number"
    | "editors_phone_number"
    | "editors_email"
    | "founder_email"
    | "editors_address"
    | "chief_post"
  > & {
    founder_phone_number: string | null;
    editors_phone_number: string | null;
  };
  domain?: string;
  className?: string;
  showFull?: boolean;
  showMiddle?: boolean;
  isDesktop?: boolean;
};

const PhoneNumberLink = ({ phoneNumber }: { phoneNumber: string | null }) => {

  return phoneNumber && phoneNumber.trim() !== "" ? (
    <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
  ) : null;
};

const EmailLinks = ({ editorsEmail, founderEmail }: { editorsEmail: string | null; founderEmail: string | null }) => (
  <p>
    <strong>Электронный адрес:</strong>&nbsp;
    {editorsEmail &&
      <>
        <a href={`mailto:${editorsEmail}`}>{editorsEmail}</a> /{" "}
      </>
    }
    <a href={`mailto:${founderEmail}`}>{founderEmail}</a>
  </p>
);

const RegistrationInfo = ({ contacts }: { contacts: ContactsProps['contacts'] }) => {
  return (
    <>
      <p className="publisher-info">
        <strong>Сетевое издание &laquo;{contacts.legal_name}&raquo;. </strong>
        {contacts.register_number && (
          <>
            Средство массовой информации зарегистрировано Федеральной службой
            по надзору в сфере связи, информационных технологий и массовых
            коммуникаций, <br />
            регистрационный номер серия&nbsp;
            <a href="/license.pdf" target="_blank" rel="noreferrer">
              ЭЛ № ФС {contacts.register_number}
            </a>
            {contacts.register_date && ` от ${contacts.register_date.replace(".", "")}.`}
          </>
        )}
      </p>
      <p>
        <strong>Учредитель:</strong> {contacts.founder}
      </p>
      <p>
        <strong>{contacts.chief_post}:</strong> {contacts.chief_editor}
      </p>
    </>
  );
};

const Contacts: React.FC<ContactsProps> = ({
  contacts,
  domain,
  className,
  showFull = false,
  showMiddle = false,
  isDesktop = true,
}) => {
  if (!contacts.chief_post) contacts.chief_post = "Главный редактор";

  return (
    <div className={className ? className : styles.contacts}>
      {showFull && (
        <p>
          &copy; Все права защищены. При использовании информации гиперссылка на
          сайт {domain} обязательна.{" "}
          <Link href="/copyright">
            <a className={isDesktop ? styles.bottomLink : styles.bottomLinkMobile} target="_blank">
              Полные правила
            </a>
          </Link>
        </p>
      )}

      {(showFull || showMiddle) && (
        <>
          <RegistrationInfo contacts={contacts} />
          <p>
            <strong>Телефон:&nbsp;</strong>
            <PhoneNumberLink phoneNumber={contacts.editors_phone_number} />
            {contacts.editors_phone_number && contacts.founder_phone_number && <> &nbsp;/&nbsp; </>}
            <PhoneNumberLink phoneNumber={contacts.founder_phone_number} />
          </p>
          <EmailLinks editorsEmail={contacts.editors_email} founderEmail={contacts.founder_email} />
          <p>
            <strong>Адрес редакции:</strong> {contacts.editors_address}
          </p>
        </>
      )}
    </div>
  );

};

export default Contacts;
