import styles from "../styles/components/Topline/ToplineMenu.module.scss";
import { FunctionComponent } from "react";
import { usePlatform } from "@/helpers/platformContext";
import { useDevice } from "@/helpers/deviceContext";
import Contacts from "@/components/Contacts";
import Informers from "./Topline/Informers";

const Footer: FunctionComponent = () => {
  const { isDesktop } = useDevice();
  const { domain, footer_column_first, contacts } =
    usePlatform();
  const isHaveContacts = !!contacts.editors_address;
  return (
    <div className="footer">
      {isHaveContacts ? (
        <>
          <Contacts
            className={styles.smallText}
            contacts={contacts}
            domain={domain}
            isDesktop={isDesktop}
            showFull
          />
          {!footer_column_first?.slice(0, 20).includes("&copy;") && (
            <div className={`${styles.smallText} !border-t-0 !mt-[-8px] !pt-0 !mb-[8px]`} dangerouslySetInnerHTML={{ __html: footer_column_first }} />
          )}
        </>
      ) : (
        <div
          className={styles.smallText}
          dangerouslySetInnerHTML={{ __html: footer_column_first }}
        />
      )}
      <Informers />
    </div>
  );
};

export default Footer;
